import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

import { enumTiposUsuario } from '../enums/enumTiposUsuario';
import { GuiaTurismoModel } from '../models/guiaTurismoModel';
import { UsuarioModel } from '../models/usuarioModel';
import { UsuarioService } from './usuario.service';


@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate {

  private flInstitucionalSubject = new BehaviorSubject<boolean>(true);

  constructor(
    private usuarioSrv: UsuarioService,

    private matSnack: MatSnackBar,
    private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const _roles = next.data['roles'] as Array<string>;
    // console.log(state.url)
    // console.log(_roles)
    if (!_roles || _roles[0] == 'livre') {
      if (state.url.includes('Institucional')) {
        this.flInstitucionalSubject.next(true);
      } else {
        this.flInstitucionalSubject.next(false);
      }
      return true;
    }
    this.flInstitucionalSubject.next(false);
    if (this.usuarioSrv.isStaticLogged) {
      let _tpUsuario: number = null;
      if (localStorage.getItem('passeioja:tpUsuario')) {
        _tpUsuario = Number(localStorage.getItem('passeioja:tpUsuario'));
      }
      if (!_tpUsuario) {
        this.matSnack.open('Necessário fazer login para executar esta função', undefined, { duration: 2000 });
        return this.router.parseUrl('/AmbosGeral/Login');
      }
      // rotina abaixo serve para garantir a seguinte situação:
      // Se outro usuário abriu outra janela fez o login com um usuário diferente,
      // ao voltar na janela original vai fazer teste sempre que navegar
      // para outra página e comparar o conteúdo deste observable (uid do menu original)
      // x localstorage (salvo na segunda janela).
      let _uidMenu: string = '';
      const sub = this.usuarioSrv.observableUidUsuarioLogged().pipe(take(1)).subscribe(uid =>
        _uidMenu = uid
      );
      sub.unsubscribe();
      // console.log(_uidMenu);
      if (localStorage.getItem('passeioja:Usuario')) {
        let _usuario = JSON.parse(localStorage.getItem('passeioja:Usuario')) as UsuarioModel;
        if (_uidMenu
          && _uidMenu != _usuario.uid) {
          this.matSnack.open('Necessário novo login', undefined, { duration: 3000 });
          return this.router.navigateByUrl('/AmbosGeral/Sair/Login');
          // // opção descartada: manter o login e seguir adiante
          // if (_usuario.tpUsuario == 1) {
          //   if (localStorage.getItem('passeioja:GuiaTurismo')) {
          //     let _guia = JSON.parse(localStorage.getItem('passeioja:GuiaTurismo')) as GuiaTurismoModel;
          //     this.usuarioSrv.carregarMenu(_tpUsuario, _usuario, _guia.flGuiaAdministrador);
          //   } else {
          //     return this.router.parseUrl('/Login');
          //   }
          // } else {
          //   this.usuarioSrv.carregarMenu(_tpUsuario, _usuario);
          // }
          // this.usuarioSrv.loginSubject.next(true);
          // return this.router.parseUrl('/AmbosGeral/Home');
        } else {
          let _tpPerfil: string = '';
          if (_tpUsuario == enumTiposUsuario.Admin_3) {
            _tpPerfil = 'admin';
          }
          if (_tpUsuario == enumTiposUsuario.Guia_1) {
            if (localStorage.getItem('passeioja:GuiaTurismo')) {
              const _guialogin: GuiaTurismoModel = JSON.parse(localStorage.getItem('passeioja:GuiaTurismo'));
              if (_guialogin.flGuiaAdministrador) {
                _tpPerfil = 'guiaAdministrador';
              } else {
                _tpPerfil = 'guiaSimples';
              }
            }
          }
          if (_tpUsuario == enumTiposUsuario.Comissionado_4) {
            _tpPerfil = 'comissionado';
          }
          if (_tpPerfil == '') {
            this.usuarioSrv.limparLocalStorage();
            this.usuarioSrv.limparMenu();
            this.matSnack.open('Seu perfil não permite executar esta função', undefined, { duration: 2000 });
            return this.router.parseUrl('/AmbosGeral/Login');
          }
          // console.log(_tpPerfil);
          if (_roles.indexOf(_tpPerfil) > -1) {
            return true;
          } else {
            this.usuarioSrv.limparLocalStorage();
            this.usuarioSrv.limparMenu();
            this.matSnack.open('Seu perfil não permite executar esta função', undefined, { duration: 2000 });
            return this.router.parseUrl('/AmbosGeral/Login');
          }
        }
      } else {
        // console.log('está sem localstorage')
        this.usuarioSrv.loginSubject.next(false);
        return this.router.parseUrl('/AmbosGeral/Login');
      }

    } else {
      // this.matSnack.open('Necessário fazer login para executar esta função', undefined, { duration: 2000 });
      this.usuarioSrv.loginSubject.next(false);
      return this.router.parseUrl('/AmbosGeral/Login');
    }
  }

  observableFlagInstitucional(): Observable<boolean> {
    return this.flInstitucionalSubject.asObservable().pipe(distinctUntilChanged());
  }


}
